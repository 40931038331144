
import { Helmet } from "react-helmet";
import "./customcss.css"
import ImageForTeamMembers from "./ImageForTeamMembers"
const TeamMembersNew = () => {
    return (
        <>
          <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
            <div id="ourteam" className="min-h-[100vh] w-full flex flex-col justify-start items-center bg-[rgba(17,10,28,1)] pb-[25vh] " >
                <p className="font-[500] text-[20px] leading-[24px] text-center tracking-[0.3em] text-[rgba(219,115,115,1)] mt-[20vh]">MEET THE FOUNDER</p>
                <div className="min-h-[100vh] flex flex-col items-center justify-start">
                    <h1 className="font-[700]  text-center w-[100%] bglineargradient mt-[5vh]
                    
                    md:text-[56px] md:leading-[72px] 
                    text-[17px]   
                    ">From Spark To Story: Crafting Impactful Content</h1>


                    <div className="flex gap-[50px] mt-[0vh]  w-[1111px]
                    
                    md:flex-row md:h-[624px] 
                    flex-col items-center
                    ">
                        {/* <ImageForTeamMembers/>
            <ImageForTeamMembers/>
            <ImageForTeamMembers/> */}
                        <div className="flex flex-col h-[99vh] 
                        
                        ">
                            <div className=" imagecontainClass w-[400px] h-[66vh] 
                            md:mt-[26vh]
                            mt-[10vh]" >

                            </div>
                           <div className="flex mt-[5vh] justify-center ">
                           <img src="/secondpage/footer/linkedin.svg" 
                               onClick={()=>{
                                window.open(' https://www.linkedin.com/in/nilesh-punadiya/', '_blank', 'noopener,noreferrer');
                               }}
                               
                           className="hover:cursor-pointer" alt="" />
                           <img src="/secondpage/footer/Twitter1.svg" 
                           onClick={()=>{
                            window.open('https://x.com/nil_punadiya', '_blank', 'noopener,noreferrer');
                           }}
                           className="ml-[1vw] hover:cursor-pointer" alt="" />
                           </div>
                        </div>
                        <div className="flex flex-col justify-center  ml-[7vw]
                        md:items-start md:mt-[6vh]
                        mt-[-14vh]
                        items-center
                        " >

                            <h1 className="font-Inter font-[500] text-[40px] leading-[48px] text-[rgba(255,255,255,1)]">Nilesh Punadiya</h1>
                            <p className="font-Inter font-[500] text-[20px] leading-[24px] text-[rgba(115,217,219,1)] my-[5vh] tracking-[0.3em]">FOUNDER & CEO  <br />
                                <span className="h-[2px]  mt-[35px] bg-[rgba(115,217,219,1)]
                                
                                md:w-[5vw]
                                w-[40vw]
                                "></span></p>

                            <p className="font-Inter font-[400] text-[16px] leading-[24px] text-[rgba(191,175,218,1)]
                            
                            md:w-[564px] md:text-start md:pr-[0px]
                            w-[90vw]  text-justify pr-[15px]
                            ">Nilesh Punadiya, the creative genius and founder of Metapher Media Labs.  With over Five years of cracking the YouTube algorithm and conjuring up content that clicks (literally), Nilesh has turned digital storytelling into an art form—and a science.

                                <br />
                                <br />
                                At Metapher Media Labs,  creativity meets strategy in the most unexpected ways. Nilesh doesn’t just think outside the box- he redefines the box. His knack for blending storytelling with cutting-edge strategies has helped countless creators turn their big ideas into even bigger successes. He’s a seasoned pro at transforming wild ideas into polished content that audiences can’t get enough of. he’s been the mad scientist behind countless successful channels, using a mix of creativity, data, and a dash of wit to make content that doesn’t just capture attention, but holds it hostage.
                                
                                <br />
                                <br />
                                With Magpiie, Nilesh is taking his vision to the next level—creating a platform that’s set to revolutionize the way creators think about content.
                                </p>
                        </div>



                    </div>

                </div>
            </div>


        </>
    )
}

export default TeamMembersNew