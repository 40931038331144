import React, { useState, useEffect } from 'react';
import './practise.css'; // Custom CSS for the sidebar
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({isOpen,setIsOpen}) => {
  // const [isOpen, setIsOpen] = useState(false);


const nav=  useNavigate();

  // Close the sidebar when clicking outside of it
  const handleClickOutside = (event) => {
    if (event.target.className === 'sidebar-overlay') {
      setIsOpen(false);
    }
  };

  // Add event listener to handle outside click when sidebar is open
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      {/* <button onClick={() => setIsOpen(true)} className="open-sidebar-btn">
        Open Sidebar
      </button> */}

      {/* Overlay when sidebar is open */}
      {isOpen && <div className="sidebar-overlay"></div>}

      {/* Sidebar */}
      <div className={`sidebar ${isOpen ? 'open' : ''} `}>
        <button className="close-btn" onClick={() => setIsOpen(false)}>
          &times; {/* Close Button */}
        </button>
      <div className='mt-[51px] h-[50vh] aman'>

      <ul className="flex flex-col w-[94%] justify-around h-[42vh]">

<li className="cursor-pointer ">
<Link
        activeClass="aactive"
        to="Features"
        spy={true}
        smooth={true}
        duration={2000}
        className="text-white font-Inter font-[400] text-[16px] leading-[24px] "
    >
      Features
    </Link>

</li>
<li className="cursor-pointer ">
<Link
        activeClass="aactive"
        to="solutions"
        spy={true}
        smooth={true}
        duration={2000}
        className="text-white font-Inter font-[400] text-[16px] leading-[24px] "
    >Solutions</Link>
</li>
<li className="cursor-pointer "
// onClick={() => {
//   nav("/AboutUs");

// }}
>  <Link
activeClass="aactive"
to="AboutUs"
spy={true}
smooth={true}
duration={2000}
className="text-white font-Inter font-[400] text-[16px] leading-[24px] "
>About Us</Link></li>
<li className="cursor-pointer ">
<Link
        activeClass="aactive"
        to="ourteam"
        spy={true}
        smooth={true}
        duration={2000}
        className="text-white font-Inter font-[400] text-[16px] leading-[24px] "
    >Our Team</Link>
</li>
<li className="cursor-pointer ">
<Link
        activeClass="aactive"
        to="contactUs"
        spy={true}
        smooth={true}
        duration={2000}
        className="text-white font-Inter font-[400] text-[16px] leading-[24px] "
    >Contact</Link>
</li>
</ul>

      </div>
      </div>
    </>
  );
};

export default Sidebar;