import { Helmet } from "react-helmet"
import "./customcss.css"
import { useState } from "react"
import axios from "axios"
import { useEffect } from "react"
import Loader from "../../AdditionalComponents/ReactSpinner"
const GetInTouchPage = () => {


  const [successSent, setSuccessSent] = useState(null);
  const [WaitingState, setWaitingState] = useState(null);

  const formSubmittedClicked = () => {

   

    if (!formdata.Name) return alert("Name is Required")
    if (!formdata.Email) return alert("Email is Required")
    if (!formdata.Subject) return alert("Subject is Required")
    if (!formdata.MailBody) return alert("Message can't be Empty")


      setWaitingState(true)
    console.log(process.env.REACT_APP_BASE_URL);
    setTimeout(() => {
      setSuccessSent(true)
      setWaitingState(false)
    }, 4000);

   

    axios.post(`${process.env.REACT_APP_BASE_URL}/v1/metapher/LandingPageFeedback`, formdata)
      .then(() => {
        SetFormData({
          Name: "",
          Email: "",
          Subject: "",
          MailBody: ""
        })
      
      })
      .catch(e => {
       
      })


  }

  const [formdata, SetFormData] = useState({
    Name: "",
    Email: "",
    Subject: "",
    MailBody: ""
  })

  const FormDataChangeFunction = (e) => {
    SetFormData({ ...formdata, [e.target.name]: e.target.value })

  }

  useEffect(()=>{
    setTimeout(() => {
      setSuccessSent(false)
      setWaitingState(false)
    }, 8000);
  },[successSent])
  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
      <div id="contactUs" className="min-h-[100vh] w-full flex  bg-[rgba(231,228,245,1)]
   
   md:flex-row md:pb-[0vh]
   flex-col
   
   pb-[5vh]
   ">
        <div className="flex-1 flex flex-col h-[100vh] justify-center 
    md:ml-[10%] md:items-start 
    items-center


    ">
          <h1 className="font-[700]  leading-[95px] letusknowclass
        
        md:text-[81.35px] md:text-start
        text-[40px]
        text-center

        ">Let Us Know <br />What You Think!</h1>
          <p className="font-[400] text-[20px] leading-[35px] mt-[10px] text-[rgba(89,75,113,1)] 
        md:text-start  md:px-[0vw]
        text-center px-[3vw]


        ">Send us your comments, suggestions, questions or feedback. <br />
            At Magpiie, we’re extremely good listeners!</p>

        </div>
        <div className="flex-1  flex justify-center items-center h-[100vh] 
    

    ">
          <div className=" bg-[rgba(255,255,255,1)] rounded-[15px]

        md:w-[469px] md:h-[596px] md:mt-[0vh]
        mt-[5vh]
        w-[92vw] h-[70vh]

        ">
            <h1 className="font-[600] text-[32px] leading-[44px] text-center text-[rgba(89,75,113,1)] font-Poppins mt-[6vh] ">Get in Touch</h1>
            <div className="flex flex-col items-start w-[80%] mx-auto ">

              <input name="Name" type="text" className="border-[1.5px]  border-[rgba(214,217,222,1)] font-[400] text-[16px] leading-[20.16px] px-[10px] rounded-[10px] mt-[4vh]  text-[rgba(135,144,158,1)]
                  md:w-[389px] md:h-[42px]
                w-[100%] h-[42px]
                " placeholder="Name"
                value={formdata.Name}
                onChange={FormDataChangeFunction}
              />
              <input name="Email" type="text" className=" border-[1.5px]  border-[rgba(214,217,222,1)] font-[400] text-[16px] leading-[20.16px] px-[10px] rounded-[10px] mt-[4vh] font-Plus-Jakarta text-[rgba(135,144,158,1)]
                  md:w-[389px] md:h-[42px]
                w-[100%] h-[42px]
                " placeholder="Email"
                value={formdata.Email}
                onChange={FormDataChangeFunction}
              />
              <input name="Subject" type="text" className=" border-[1.5px]  border-[rgba(214,217,222,1)]  font-[400] text-[16px] leading-[20.16px] px-[10px] rounded-[10px] mt-[4vh] font-Plus-Jakarta text-[rgba(135,144,158,1)]
                 md:w-[389px] md:h-[42px]
                w-[100%] h-[42px]
                " placeholder="Subject"
                value={formdata.Subject}
                onChange={FormDataChangeFunction}
              />
              <textarea name="MailBody" type="text" className=" h-[120px] border-[1.5px]  border-[rgba(214,217,222,1)] font-[400] text-[16px] leading-[20.16px] px-[10px] py-[15px] rounded-[10px] mt-[4vh] text-base  outline-none resize-none font-Plus-Jakarta text-[rgba(135,144,158,1)]
                  md:w-[389px] 
                w-[100%] 
                " placeholder="Your message"
                value={formdata.MailBody}
                onChange={FormDataChangeFunction}
              />
              <button className={` 
              ${WaitingState?"flex justify-center items-center":""}
              rounded-[10px] bg-[rgba(127,106,160,1)] font-[600] text-[16px] leading-[20.16px] text-center text-[rgba(255,255,255,1)] mt-[4vh]
                  md:w-[389px] md:h-[42px]
                w-[100%] h-[42px]
                `}
                onClick={() => {
                  formSubmittedClicked();
                }}
              >{WaitingState?<Loader/>: "Send Message"}</button>
              {
               
              }

              {successSent && <div className="flex justify-center mt-[16px] w-full">
                <p className=" font-Poppins text-[15px]"> Feedback Sent</p>  <img src="/doneSvg.svg" alt="message Sent" className="w-[15px] ml-[5px]" />
              </div>}
            </div>

          </div>


        </div>

      </div>


    </>
  )
}

export default GetInTouchPage