
import "../src/assets/custom.css"
import SecondPage from "./components/SecondPage.jsx"
import ThirdPageProductivity from "./components/ThirdPageProductivity.jsx"
import FourthPageLookingFor from "./components/FourthPageLookingFor.jsx"
import FifthPageFeatures from "./components/FifthPageFeatures.jsx"
import SixthPagePlan from "./components/SixthPagePlan.jsx"
import ImageDashboard from "./components/ImageDashboard.jsx"
import Footer from "./components/Footer.jsx"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import TeamMembers from "./components/aboutUsComponents/TeamMembers.jsx"
import GetInTouchPage from "./components/aboutUsComponents/GetInTouchPage.jsx"
import SecondPageAboutus from "./components/aboutUsComponents/AboutUsSecondPage.jsx"
// import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import TeamMembersNew from "./components/aboutUsComponents/TeamMemberNew.jsx"
import NavbarResponsiveComponentSM from "./responsiveComponents/NavbarResponsive/NavbarResponsiveComponentSM.jsx"
import { Helmet } from "react-helmet"
import { motion } from 'framer-motion';

import { Typewriter } from 'react-simple-typewriter'
import AnimatedWords from "./AdditionalComponents/AnimationFaded.jsx"
import { useEffect } from "react"
import { scroller } from 'react-scroll';

const LandingPageFirstPage = () => {

  const nav = useNavigate();


  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 2000,
      smooth: 'easeInOutQuart'
    });
  };
  const location = useLocation();

  useEffect(() => {
    if (location.state?.section) {
      scroller.scrollTo(location.state.section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [location]);


  const text = " Content Production Management";
  const text1=""
   // Variants for the container and each letter
   const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.04, delayChildren: 0.1 },
    },
  };

  const child = {
    hidden: {
      opacity: 0,
      y: 30,  // Start slightly below
      scale: 0.8,  // Slightly scaled down
    },
    visible: {
      opacity: 1,
      y: 0,  // Moves to the original position
      scale: 1,  // Grows to full size
      transition: {
        type: 'spring',
        damping: 16,
        stiffness: 120,
      },
    },
  };




  return (
    <>


<Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
      <>
        <div className="gradient_bg 

        w-[100vw]
        

        
          
        ">

          <div className="  w-full h-[100vh] pt-[24px] 
          
          
         
          ">
            {/* this is responsive navbar */}
            <nav className="w-[95vw] h-[66px]   mx-[auto] sticky-navbar   left-[2%]
             md:block
             hidden
            ">
              <div className="w-full h-full my-0 mx-[auto] rounded-custom32px   py-0 px-[30px]  flex justify-between items-center
  bg_customcss_nav
  ">

                <div className="h-full flex items-center">
                 <img src="/magpiie logo.svg" className="w-[149px] h-[37px] cursor-pointer " alt="not available" onClick={() => {
                    nav("/");
                    scrollToTop();

                  }} />
                  {/* <p>MAGPIIE</p> */}
                </div>
                <div className="w-[45%]">
                  <ul className="flex w-[94%] justify-around">

                    <li className="cursor-pointer ">
                    <Link
                            activeClass="aactive"
                            to="Features"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                        >
                          Features
                        </Link>
                  
                    </li>
                    <li className="cursor-pointer ">
                    <Link
                            activeClass="aactive"
                            to="solutions"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                        >Solutions</Link>
                    </li>
                    <li className="cursor-pointer "
                    // onClick={() => {
                    //   nav("/AboutUs");

                    // }}
                    >  <Link
                    activeClass="aactive"
                    to="AboutUs"
                    spy={true}
                    smooth={true}
                    duration={2000}
                    className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                >About Us</Link></li>
                    <li className="cursor-pointer ">
                    <Link
                            activeClass="aactive"
                            to="ourteam"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                        >Our Team</Link>
                    </li>
                    <li className="cursor-pointer ">
                    <Link
                            activeClass="aactive"
                            to="contactUs"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                        >Contact</Link>
                    </li>
                  </ul>

                </div>
                <div>
                  <button
                    onClick={() => {
                      nav("/e/Register");
                    }}
                    className="w-[180px] font-Inter font-[400] text-[16px] leading-[24px] tracking-[2px]   h-[52px] rounded-[8px] bg-[rgba(17,10,28,1)] text-white ">
                    JOIN WAITLIST
                  </button>
                </div>


              </div>


            </nav>

            <NavbarResponsiveComponentSM/>




        



{/* for big screen */}
         <>
            <motion.div
            
            variants={container}
        initial="hidden"
        animate="visible"
            id="atthetop" className="
            hidden
            md:block
            font-sans w-[96vw] mt-[247px] mx-auto  text-center  text-[64px] leading-custom104px tracking-custom1.6 font-[500] colorCustomFirstPage ">
              <h1 className="
          
          ">
            Content Production Management
            
         
            
           
              </h1>
              <h1 className=""> 
with   <Typewriter words={["Effortless Team Integration","Integrated Guidelines","Performance Tracking","Collaboration Tools","Task Management","Drafts & Revisions"]}  loop/>
{/* <AnimatedWords words={["Effortless Team Integration","Integrated Guidelines","Performance Tracking","Collaboration Tools","Task Management","Drafts & Revisions"]}/> */}


</h1>

            </motion.div>
            <div className=" 
            hidden
            md:block
            font-sans mt-[70px] w-[1248px]  mx-auto text-center leading-custom40px text-[24px] tracking-custom1.6 colorCustomFirstPage font-[500]">
              <p>Empower teams for seamless collaboration and heightened productivity. Our innovative features streamline task workflows, foster effective communication & enhance creativity.</p>

            </div>
         </>

         <>
         {/* for mobile  */}

         <div id="atthetop" className="
         md:hidden
         mt-[8vh]

         
         font-sans w-[100vw]  mx-auto  text-center   leading-custom104px tracking-custom1.6 font-[500] colorCustomFirstPage ">
              <h1 className="
              text-[40px]
             md:text-[64px]
             w-full
             text-center
              ">Content Production Management
              </h1>
              <h1 className="
              
                 
                 w-full
                 text-[40px]
             md:text-[64px]
              ">with Effortless Team Integration</h1>

            </div>
            <div className=" 
            md:hidden
            font-sans mt-[70px] w-[100vw]  mx-auto text-center  leading-custom40px text-[24px] tracking-custom1.6 colorCustomFirstPage font-[500]">
              <p>Empower teams for seamless collaboration and heightened productivity. Our innovative features streamline task workflows, foster effective communication & enhance creativity.</p>

            </div>
         </>


          </div>
          <ImageDashboard />

        </div>

        <SecondPage />
        <ThirdPageProductivity />
        <FourthPageLookingFor />
        <FifthPageFeatures />

        <SecondPageAboutus />
        <TeamMembersNew/>
        <GetInTouchPage />
        {/* <SixthPagePlan/> */}
        <Footer />
      </></>
  )
}

export default LandingPageFirstPage