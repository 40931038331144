
import { useNavigate } from "react-router-dom"
import DivForSecondPage from "../AdditionalComponents/DivForSecondPage"
import ImageForSecondPage from "../AdditionalComponents/ImageForSecondPage"
import "../assets/custom.css"
import { useState } from "react"
import { Helmet } from "react-helmet"
const SecondPage = () => {



  const buttondata=[
    {
     number:0,
     title:"Ideation",
     src:"/secondpage/content-idea-icon.svg",
     headingOne:"Spark",
     headingTwo:"with an idea",
     comment:"Generate and brainstorm creative ideas, using tools like AI assistants and mood boards to inspire and organize concepts.",
     divHeading:"Content Ideation",
     divComment:"Generate creative ideas using AI tools ",
     divSrc:"/secondpage/secondDiv/content-idea-icon.svg"


    }
    ,
    {
      number:1,
      title:"Scripting",
      src:"/secondpage/script-icon.svg",
      headingOne:"Shape",
      headingTwo:"with stories",
      comment:"Write detailed scripts to guide the production process, ensuring clarity and coherence in the content.",
      divHeading:"Content Scripting",
      divComment:"Write clear and detailed scripts",
      divSrc:"/secondpage/secondDiv/script-icon.svg",
      
    },
    {
      number:2,
      title:"Voiceover",
      src:"/secondpage/voiceover-icon.svg",
      headingOne:"Captivate",
      headingTwo:"with voice",
      comment:"Record high-quality voice overs that complement the video content, ensuring clear and engaging narration.",
      divHeading:"Voiceover",
      divComment:"Record engaging voiceovers",
      divSrc:"/secondpage/secondDiv/voiceover-icon.svg",
    },
    {
      number:3,
      title:"Shoot",
      src:"/secondpage/video-shoot-icon.svg",
      headingOne:"Capture",
      headingTwo:"the magic",
      comment:"Capture high-quality video footage, utilizing proper lighting, camera angles to enhance visual appeal.",
      divHeading:"Video Shoot",
      divComment:"Capture high-quality video footage",
      divSrc:"/secondpage/secondDiv/video.svg",
    },
    {
      number:4,
      title:"Editing",
      src:"/secondpage/video-edit-icon.svg",
      headingOne:"Polish",
      headingTwo:"to Perfection",
      comment:"Refine and polish the recorded footage, adding effects & ensuring smooth flow with professional quality.",
      divHeading:"Video Editing",
      divComment:"Refine footage with effects and transitions",
      divSrc:"/secondpage/secondDiv/video-edit-icon.svg",
    },
    {
      number:5,
      title:"Publishing",
      src:"/secondpage/video-setup-icon.svg",
      headingOne:"Ready",
      headingTwo:"for release",
      comment:"Schedule and release the final content across platforms, optimizing for reach and engagement using analytics and performance insights.",
      divHeading:"Video Publishing",
      divComment:"Schedule and release content, optimizing for reach",
      divSrc:"/secondpage/secondDiv/video-setup-icon.svg",
    }
  ]

  const [CategorySelected, setCategorySelected] = useState(1);
  const nav = useNavigate();


  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
      <div  className=" 
      hidden
       md:flex
      
      h-[100vh] w-full bgSecondPage  z-[1000] relative  ">

        <div className="h-full flex flex-1  text-[white] flex-col items-start justify-center ">
          <div className="flex flex-col ">
         {
          buttondata.map((e)=>{
            return(
              <>
              {
                CategorySelected===e.number?<>
                   <h1 className="text-[80px] font-[600] leading-custom104px tracking-[-3px] div-animation  ml-[6vw]">{e.headingOne} <br /> {e.headingTwo}</h1>
                   <p className="w-[25vw] font-[400] text-[16px] leading-[29px] text-[rgba(191,175,218,1)] ml-[6vw] mt-[19px] div-animation ">{e.comment}</p>
                </>:<></>
              }
              </>
            )
            
          })
         }
          </div>

          <div className="customForSecondpageButtonAtfirstDiv mt-[69px] !p-[34px] ml-[6vw]">
            {/* <button
              className=" flex items-center buttonInthesecondDiv"
            > <img src="/secondpage/1.svg" alt="" /></button> */}
          {
            buttondata.map((e)=>{
              return(<>
              {
                CategorySelected===e.number?<>
                <button className="flex justify-start items-center bg-[rgba(127,106,160,1)] text-white w-[148px] h-[49px] rounded-[8px] button-animate ">
              <img src={e.src} className="w-[32px] h-[32px] ml-[12px] text-white" alt="" />
              <p className="font-Plus-Jakarta font-[500] text-[18px] leading-[32px] ml-[7px] ">{e.title}</p>

            </button>
                </>:<>
                
                <ImageForSecondPage
                src={e}
                click={()=>{
                  setCategorySelected(e.number)
                }}
                
                
                />
                </>
              }
              </>)
            })
          }


            


       



          </div>

        </div>

        <div className="h-full flex-1 flex flex-col justify-start items-start pt-[30px]">
          <div className="mt-[0px]">
            <p className="font-[500] text-[20px] leading-[24px] tracking-[5px]    text-[rgba(219,115,115,1)]
]
          ">CONTENT FLOW MANAGEMENT</p>
            <h1 className="CustomClassforh1inseconddiv">Track Content Creation Flow Using Sequential Stages</h1>
          </div>

         {
           buttondata.map((e)=>{
            return(<>
            <DivForSecondPage
            src={e.divSrc}
            heading={e.divHeading}
            comment={e.divComment}
            e={e}
            selected={CategorySelected}
            onselect={setCategorySelected}
            />
            </>)
           })
         }

          <button
            className="customforbuttonsecondpageseconddiv"
            onClick={() => {
              nav("/e/Register")
            }}
          > Join The Waitlist</button>

        </div>


      </div>
    </>
  )
}

export default SecondPage