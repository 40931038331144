import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const AnimatedWords = ({ words, duration = 3 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, duration * 1000); // Convert seconds to milliseconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [words.length, duration]);

  return (
    <div style={{ position: 'relative', height: '100px', overflow: 'hidden' }}>
      {words.map((word, index) => (
        <motion.div
          key={index}
          style={{
            position: 'absolute',
            width: '100%',
            // top: '50%',
            // left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: index === currentIndex ? 1 : 0,
            y: index === currentIndex ? 0 : 20,
          }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          {word}
        </motion.div>
      ))}
    </div>
  );
};

export default AnimatedWords;
