import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Practise from "../../practise"
export default function Hamberger({opened,setOpened}) {
//   const [opened, setOpened] = useState(false);
 const nav = useNavigate();
  return (
<>
<div className='flex jsutify-center items-center w-[37vw]'>


<div className='w-full h-full flex justify-center items-center'>
                  <button
                    onClick={() => {
                      nav("/e/Register");
                    }}
                    className="w-[23vw] font-Inter font-[400] text-[8px] leading-[24px] tracking-[2px]   h-[5vh]  rounded-[8px] bg-[rgba(17,10,28,1)] text-white ">
                    JOIN WAITLIST
                  </button>
                </div>
  
<div 
    onClick={()=>{
        setOpened(!opened);
    }}
    className={classNames(`tham tham-e-squeeze tham-w-6`, { 'tham-active': opened })}>
      <div className="tham-box">
        <div className="tham-inner" />
      </div>


      <Practise  isOpen={opened} setIsOpen={setOpened}/>

  
    </div>

    </div>
</>
  );
}