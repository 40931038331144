
import { Helmet } from "react-helmet"
import "../../assets/custom.css"
const SecondPageAboutus = () => {
    return (
        <>
          <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
            <div id="AboutUs" className=" w-full bgSecondPage flex  
            md:flex-row  md:h-[100vh] md:pb-[0vh]
            flex-col
            pb-[5vh]

            ">

                <div className="h-full flex flex-1  text-[white] flex-col items-center justify-start 
                md:pt-[18vh]
                pt-[5vh]
                ">
                    <div className="flex flex-col  
                   md:items-start
                   items-center
                    ">
                        <h1 className=" font-[600] leading-custom104px tracking-[-3px]
                        md:text-[80px]
                        text-[40px]
                        md:text-justify
                        text-center
                        ">About Us</h1>
                        <img className="w-[329px] h-[158px]    
                        md:ml-[-66px] md:mt-[4vh]
                        mt-[-5vh] ml-[-14vw] 

                        
                        
                        " src="/secondpage/aboutus/metapher media labs 1.svg" alt="" />

                        {/* <div className="w-[329px] h-[158px] "> </div> */}
                        <p className=" font-[400] text-[16px] leading-[29px] text-[rgba(191,175,218,1)] mt-[-11px]
                        
                        md:w-[26vw] md:text-start
                        w-[80vw]
                        text-center
                        ">Metapher Media Labs is an innovative media tech  startup aiming to revolutionize the way content is created, consumed, and distributed.  </p>
                    </div>



                </div>

                <div className="h-full flex-1 flex flex-col 
                md:justify-start md:items-start
                justify-center items-center
                ">
                    <div className="mt-[102px]  
                     md:text-start  md:items-start md:block
                    flex flex-col items-center text-center
                    ">
                        <p className="font-[500] text-[20px] leading-[24px] tracking-[5px]    text-[rgba(219,115,115,1)]
]
          ">THIS IS WHO WE ARE</p>
                        <h1 className="CustomClassforh1inseconddiv w-[70%] mt-[3vh]">Empowering Creators through
                            Storytelling and Innovation</h1>
                    </div>


                    <div className="text-white mt-[10vh]
                    md:block 
                    flex flex-col items-center

                    ">
                        <h1 className="font-[500] text-[20px] leading-[24px] ">Our Mission</h1>
                        <p className="font-[400] text-[16px] leading-[24px] text-[rgba(191,175,218,1)] mt-[2vh] 
                        md:w-[33vw] md:text-start
                        text-center
                        w-[90vw]
                        ">To be a global leader in digital storytelling and software innovation, empowering creators and brands to connect with their audiences through compelling, meaningful, and impactful content.</p>
                    </div>
                    <div className="text-white mt-[10vh]
                    md:block 
                    flex flex-col items-center
                    ">
                        <h1 className="font-[500] text-[20px] leading-[24px] ">Our Vision</h1>
                        <p className="font-[400] text-[16px] leading-[24px] text-[rgba(191,175,218,1)] mt-[2vh] text-center
                          md:w-[33vw] md:text-start
                        w-[90vw]
                        ">To provide innovative, high-quality content solutions and software tools that drive engagement and growth. We aim to create content that resonates, inspires, and leaves a lasting impression while equipping content creators with the technology they need to thrive in the digital landscape.</p>
                    </div>



                </div>


            </div>
        </>
    )
}

export default SecondPageAboutus