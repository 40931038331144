


// eslint-disable-next-line react/prop-types
const ImageForSecondPage = (props) => {
  console.log(props)
  return (
    <>
   <img src={props?.src?.src} alt="hello" 
   className="h-[32px] w-[32px] hover:cursor-pointer"
   onClick={props?.click}
   />
   </>
  )
}

export default ImageForSecondPage