



import AboutUsPage from "./components/AboutUsPage.jsx"
import { BrowserRouter as Router, Routes, Route, useNavigate, BrowserRouter, useLocation } from 'react-router-dom';
import LandingPageFirstPage from "./LandingPageFirstPage.jsx";
import LandingPage from "./pages/LandingPage/LandingPage.js";
import SignUpPage from "./pages/SignupPage/SignupPage.js";
import AfterSignup from "./pages/AfterSignup/AfterSignup.js";
import TermsAndCondition from "./components/Conditions/TermsAndCondition.jsx";
import CookiesPolicy from "./components/Conditions/CookiesPolicy.jsx";
import TermsOfService from "./components/Conditions/TermsOfService.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";
import Practise from "./practise.jsx";
import NewsignUpPage from "./pages/SignupPage/NewSignupPage.jsx";





export default function App() {




  console.log(process.env.REACT_APP_BASE_URL);
  return (
    <>


    <ScrollToTop/>
    <Routes>
      {/* <Route path="/a" element={<Practise/>}/>/ */}
      <Route  path="/"  element={<LandingPageFirstPage/>}/>
      <Route  path="/AboutUs"  element={<AboutUsPage/>}/>
      {/* <Route  path="/e/landingPage"  element={<LandingPage/>}/> */}
      <Route  path="/e/Register"  element={<NewsignUpPage/>}/>
      <Route  path="/ee/Register"  element={<SignUpPage/>}/>
      <Route  path="/e/SignUpDone"  element={<AfterSignup/>}/>

      <Route path="Terms-And-Conditions" element={<TermsAndCondition/>}/>
      <Route path="CookiesPolicy" element={<CookiesPolicy/>}/>
      <Route path="TermsOfService" element={<TermsOfService/>}/>
    </Routes>
    
    
 
      

    </>
  )
}