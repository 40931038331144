import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
// import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Footer from '../Footer';
import "./conditioncss.css"
import NavbarCondition from './NavbarCondition';
import NavbarforCondition from './NavbarforCondition';
import { Helmet } from 'react-helmet';

const CookiesPolicy = () => {

    const nav = useNavigate();
    return (
        <>
          <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
            {/* <NavbarCondition/> */}
<NavbarforCondition/>




            <div className='min-h-[calc(100vh - 66px)] w-full flex  mt-[16vh]  pb-[10vh] shadow-md
           

            '>
                <div className='flex-[0.25] flex-col items-center  border-r  
                md:flex hidden
                '>
                    <div className='flex flex-col mt-[0vh] items-start h-[20vh] justify-around w-[11vw]' >
                        <h1 className='font-Inter font-[600] text-[14px] leading-[16px] text-[rgba(68,68,68,1)]'>TERMS AND AGREEMENTS</h1>
                        <div className='flex flex-col h-[12vh] justify-around'>
                            <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)]' 
                            onClick={()=>{
                                nav("/TermsOfService")
                            }}>Terms of Service</p>
                            {/* <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)]'>Product Terms</p> */}
                            {/* <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)]'>Creator Aggrement</p> */}
                        </div>

                    </div>

                    <div className='flex flex-col mt-[1vh] items-start h-[20vh] justify-around w-[11vw]' >
                        <h1 className='font-Inter font-[600] text-[14px] leading-[16px] text-[rgba(68,68,68,1)]'>PRIVACY RESOURCES</h1>
                        <div className='flex flex-col h-[12vh] justify-around'>
                            <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)]'
                            onClick={()=>{
                                nav("/Terms-And-Conditions")
                            }}
                            >Privacy Policy</p>
                            <p className='font-Inter font-[500] text-[14px] leading-[16px]   text-[rgba(20,110,245,1)]'>Cookie Policy</p>
                            {/* <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)]'>Data Processing</p> */}
                        </div>

                    </div>
                    <div>

                    </div>

                </div>

                <div className='flex-1 flex flex-col pl-[4vw]'>
                    <div className='w-[958px]'>
                        <h1 className='font-Inter font-[600] text-[40px] leading-[48.41px] text-[rgba(89,75,113,1)]'>COOKIES POLICY</h1>
                        <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)] mt-[5vh] '>Effective date:&nbsp;&nbsp;August 17, 2024</p>
                        <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)] mt-[1vh] mb-[8vh]'>Effective date:&nbsp; August 17, 2024</p>


                    </div>
                    <div className='w-[958px] bg-black h-[0.1px]'></div>

                    <div className='flex w-[958px] mt-[6vh] relative'>
                        <div className='w-[631px] flex flex-col text-justify'>
                            <div
                                id='InterpretationAndDefinitions'
                                className='w-[631px]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>1. What Are Cookies?

                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>

                                    Cookies are small text files placed on your device (computer, mobile device, or any other device) by websites you visit. They are widely used to make websites work or to enhance their efficiency and to provide reporting information. Cookies can be "Persistent" or "Session" cookies:
                                    <br />
                                    <br />
                                    <h3 className='mb-[2vh] mt-[2vh] text-[15px] text-black font-[600] inline '>
                                        Session Cookies:  &nbsp;

                                    </h3>
                                    temporary and expire once you close your web browser.Session Cookies: <br />
                                    <br />

                                    <p className='text-[15px] text-black font-[600] inline '> Persistent Cookies:  &nbsp; </p> These cookies remain on your device for a set period or until you delete them.
                                    <br />
                                    <br />
                                </p>

                            </div>
                            <div
                                id='CollectionAndUsingYourPersonalData'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>2.How We Use Cookies
                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>
                                    We use cookies for the following purposes:
                                    <br />






                                    &nbsp;               <p className='text-[15px] text-black font-[600] inline'>  Necessary / Essential Cookies</p>
                                    <br />
                                    <br />

                                    &nbsp; &nbsp; &nbsp; &nbsp;  &#9702; <h4 className='text-[15px] text-black font-[600] inline'>Type:</h4> &nbsp; Session Cookies
                                    <br />

                                    &nbsp; &nbsp; &nbsp; &nbsp;  &#9702; <h4 className='text-[15px] text-black font-[600] inline'>Administered by:</h4> &nbsp; Us
                                    <br />

                                    &nbsp; &nbsp; &nbsp; &nbsp;  &#9702; <h4 className='text-[15px] text-black font-[600] inline'>Purpose:</h4> &nbsp;  These cookies are essential to provide you with services available <br />    &nbsp; &nbsp; &nbsp; &nbsp;     &nbsp; &nbsp; &nbsp; &nbsp;    &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; through the website and to enable you to use its features. They help to

                                    <br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     &nbsp; &nbsp; &nbsp; &nbsp;    &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; authenticate users and prevent fraudulent use of user accounts.

<br />
<br />


                                    &nbsp;               <p className='text-[15px] text-black font-[600] inline'> Cookies Policy / Notice Acceptance Cookies:
                                    </p>
                     <br />
                     <br />

                     &nbsp; &nbsp; &nbsp; &nbsp;  &#9702; <h4 className='text-[15px] text-black font-[600] inline'>Type:</h4> &nbsp; Persistent Cookies

                     <br />

                     &nbsp; &nbsp; &nbsp; &nbsp;  &#9702; <h4 className='text-[15px] text-black font-[600] inline'>Administered by:</h4> &nbsp; Us
                     <br />

                        &nbsp; &nbsp; &nbsp; &nbsp;  &#9702; <h4 className='text-[15px] text-black font-[600] inline'>Purpose:</h4> &nbsp; These cookies identify if users have accepted the use of cookies on 
                        <br />  &nbsp; &nbsp; &nbsp; &nbsp;     &nbsp; &nbsp; &nbsp; &nbsp;    &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; the website.



<br />
<br />
&nbsp;               <p className='text-[15px] text-black font-[600] inline'> Functionality Cookies:

                                    </p>
                     <br />
                     <br />

                     &nbsp; &nbsp; &nbsp; &nbsp;  &#9702; <h4 className='text-[15px] text-black font-[600] inline'>Type:</h4> &nbsp; Persistent Cookies

                     <br />

                     &nbsp; &nbsp; &nbsp; &nbsp;  &#9702; <h4 className='text-[15px] text-black font-[600] inline'>Administered by:</h4> &nbsp; Us
                     <br />

                        &nbsp; &nbsp; &nbsp; &nbsp;  &#9702; <h4 className='text-[15px] text-black font-[600] inline'>Purpose:</h4> &nbsp;  These cookies allow us to remember choices you make when you use the
                        
                        <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;website, such as remembering your login details or language 
                        <br /> 












                                </p>

                            </div>
                            <div
                                id='UseofPersonalData'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>3. Your Choices Regarding Cookies
                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>
                                    <br />
                                    <br />
                                    If you prefer to avoid the use of cookies on our website, you must first disable the use of cookies in your browser and then delete the cookies saved in your browser associated with this website. You may use this option to prevent the use of cookies at any time.
If you do not accept our cookies, you may experience some inconvenience in your use of the website, and some features may not function properly.

</p>

                            </div>
                            <div
                                id='TransferofYourPersonalData'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>4. How to Delete or Block Cookies
                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>
                                    <br />
                                    <br />
                                    You can restrict or block cookies through your browser settings. The "Help" section of your browser will provide you with more details. Here are some links to common browsers' cookie management support pages:
                                    <br />
                                    <br />
           &nbsp;&nbsp;&nbsp;&nbsp; &bull; Google Chrome
           <br />

           &nbsp;&nbsp;&nbsp;&nbsp; &bull; Mozilla Firefox
           <br />

           &nbsp;&nbsp;&nbsp;&nbsp; &bull; Apple Safari
           <br />

           &nbsp;&nbsp;&nbsp;&nbsp; &bull; Microsoft Edge

           <br />
           <br />
           For more information about cookies and how to control, disable, or delete them, please visit <a href="https://www.allaboutcookies.org" target='_blank'>allaboutcookies.org</a>.


                                </p>

                            </div>
                            <div
                                id='DeleteYourPersonalData'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>5.Changes to This Cookies Policy</h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>
                                    <br />
                                    
                                    We may update our Cookies Policy from time to time. Any changes will be posted on this page with an updated "Last updated" date. We encourage you to review this policy periodically for any changes.

                                </p>

                            </div>
                            <div
                                id='Disclosureofyourpersonaldata'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>6. Contact Us

                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>
                                    <br />
                                    

                                    If you have any questions about our use of cookies or this Cookies Policy, you can contact us:
By email: contact@magpiie.com

                                </p>

                            </div>
                        





                        </div>

                        <div className='w-[258px] ml-[4vw]   relative h-full'>
                            <div className='sticky top-[53px]'>
                                <h1 className='font-[600] font-Inter text-[14px] leading-[16px] mb-[4vh] tracking-[0.016rem]'>TABLE OF CONTENTS</h1>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>   <Link
                                    activeClass="active"
                                    to="InterpretationAndDefinitions"
                                    spy={true}
                                    smooth={true}
                                    duration={2000}
                                    className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                >
                                    1. What Are Cookies?
                                </Link></p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="CollectionAndUsingYourPersonalData"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >

                                        2. How We Use Cookies
                                    </Link>
                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="UseofPersonalData"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >

                                        3. Your Choices Regarding Cookies
                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="TransferofYourPersonalData"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >


                                        4. How to Delete or Block Cookies
                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="DeleteYourPersonalData"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >



                                        5. Changes to This Cookies Policy
                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>

                                    <Link
                                        activeClass="active"
                                        to="Disclosureofyourpersonaldata"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >



                                        6. Contact Us

                                    </Link>


                                </p>
                              
                            </div>



                        </div>



                    </div>



                </div>



            </div>

            <Footer />


        </>
    )
}

export default CookiesPolicy