import "./customcss.css"
const ImageForTeamMembers = () => {
    return (
        <>
            <div className="w-[400px] h-[480px] rounded-[16px] flex relative imagebg outer-div overflow-hidden" style={{
                  background: 'url("/secondpage/aboutus/fb367be13e8017a75d7585a9d461a0c2.png")',
               
                  objectFit:"contain",
                  backgroundSize:"cover",
                  backgroundPositionY:"center"
            }}>

                <div className="box bottom-[25px] left-[7%] w-[20vw] h-[12vh] origin-[center] absolute rounded-[24px]  inner-div border  flex flex-col items-start px-[27px] py-[11px] overflow-hidden">
                    <h1 className="font-[400] text-[20px] leading-[19.2px] text-center text-[rgba(255,255,255,1)] font-sans">Nilesh Punadiya</h1>
                    <p className=" mt-[6px] font-[400] text-[16px] leading-[19.2px] text-center text-[rgba(221,221,221,1)] font-sans">Founder & CEO</p>
                    <p className="font-[400] text-[16px] leading-[21px] mt-[5px] text-start text-[rgba(221,221,221,1)] font-sans pafterhover">I'm Nilesh Punadiya, the founder of Metapher Media Labs, where we’re all about shaking things up in the world of content. 5 years ago, I embarked on this crazy adventure to revolutionize how content is created, consumed, and distributed. At Metapher Media Labs, we're not just thinking outside the box – we’re completely redesigning it with our innovative software solutions and services. Whether it's crafting compelling stories or ensuring they reach the right audience, we're here to make the magic happen.</p>
                </div>


            </div>


        </>
    )
}

export default ImageForTeamMembers