import { useNavigate } from "react-router-dom";


const Footer = () => {
    const nav=useNavigate();
    const navigate=useNavigate();
  return (



   <>
   <div className=" width-full flex flex-col items-center
   md:min-h-[405px]

   ">
    <div className="flex w-full  
    md:h-[45vh]
    flex-col
    md:flex-row
    ">
        <div className="flex-1 flex flex-col justify-start mt-[5vh]  items-start  
         gap-[5vh]
        md:pl-[114px] 
        pl-[1vw]

        ">
            <div className="flex flex-col">
                <img src="/secondpage/footer/magpiie logo.svg"  className="w-[149px] h-[37px]" alt="" />
                <p className="font-Poppins font-[400] text-[16px] leading-[24px] text-[rgba(4,5,5,1)] mt-[29px] ">Magpiie is a product of Metapher Media Labs </p>
                {/* <p className="font-Poppins font-[400] text-[14px] leading-[21px] text-[rgba(4,5,5,0.8)] mt-[13px]">Address: <br/>
                18, Mangal Park, Three Hanuman Road, Deesa, In</p> */}
 
            </div>
            <div className="flex  justify-center items-center gap-[3vw] font-Poppins  ">
                <div className="flex  items-start flex-col  ">
                {/* <img className="h-[17px]" src="/secondpage/footer/Vector (2).svg" alt="" /> */}
                    <p className=" font-Poppins font-[400] text-[14px] leading-[21px] text-[rgba(4,5,5,0.8)]" > Email  </p>
                    <p className="font-Inter font-[400] text-[16px] leading-[24px] mt-[10px] text-black">support@metapher.io</p>
                </div>

                <div className="flex flex-col">
                    {/* <p className="font-[400] text-[14px] leading-[21px]">Phone Number</p> */}
                    {/* <p className="font-[400] text-[16px] leading-[24px]">+91 7600327545</p> */}
                </div>
            </div>

        </div>
        <div className="flex-1 h-full  mt-[5vh]  flex flex-col
        md:pl-[150px] md:flex-row
        pl-[1vw]
        ">
            <div className=" flex flex-col items-start justify-around h-[25vh] font-Poppins
            md:h-[31vh] md:flex-1 
            ">
                <h1 className="hover:cursor-pointer font-[800] text-[14px] leading-[21px] ">Company</h1>
                <p className="hover:cursor-pointer font-[400] text-[16px] leading-[24px] text-[rgba(4,5,5,0.8)]"
                onClick={()=>{
                    navigate('/', { state: { section: 'Features' } });
                }}
                >Features</p>
                <p className="hover:cursor-pointer font-[400] text-[16px] leading-[24px] text-[rgba(4,5,5,0.8)]"
                onClick={()=>{
                    navigate('/', { state: { section: 'solutions' } });
                }}
                >Solutions</p>
                <p className="hover:cursor-pointer font-[400] text-[16px] leading-[24px] text-[rgba(4,5,5,0.8)]"
                 onClick={()=>{
                    navigate('/', { state: { section: 'ourteam' } });
                }}
                >Our Team</p>
                <p className="hover:cursor-pointer font-[400] text-[16px] leading-[24px] text-[rgba(4,5,5,0.8)]"
                
                onClick={()=>{
                    navigate('/', { state: { section: 'AboutUs' } });
                }}

                >About Us</p>

            </div>
            <div className="flex flex-col items-start justify-around h-[25vh] font-Poppins
            md:flex-1 md:h-[31vh]
            ">
            <h1 className="hover:cursor-pointer font-[800] text-[14px] leading-[21px] ">Legal</h1>
                <p className="hover:cursor-pointer font-[400] text-[16px] leading-[24px] text-[rgba(4,5,5,0.8)]"
                onClick={()=>{
                    nav("/TermsOfService")
                }}
                >Terms Of Service</p>
                <p className="hover:cursor-pointer font-[400] text-[16px] leading-[24px] text-[rgba(4,5,5,0.8)]"
                  onClick={()=>{
                    nav("/Terms-And-Conditions")
                }}
                >Privacy Policy</p>
                <p className="hover:cursor-pointer font-[400] text-[16px] leading-[24px] text-[rgba(4,5,5,0.8)]"
                  onClick={()=>{
                    nav("/CookiesPolicy")
                }}
                >Cookies Policy</p>
                <p className="hover:cursor-pointer font-[400] text-[16px] leading-[24px] text-[rgba(4,5,5,0.8)]"> &nbsp;</p>

            </div>


        </div>
        {/* to be displayed on mobile   */}
 
        <div className="flex-1 h-full flex flex-col items-start pl-[1vw] 
        md:pl-[7vw] md:pb-[0px]

        pb-[4vh]
        ">
            <h1 className="text-[24px] font-[600] leading-[36px] mt-[5vh] font-Poppins">Be the First to Experience <br/> Magpiie!</h1>
            <button 
               onClick={()=>{
                nav("/e/Register");
              }}
            className="w-[320px] mt-[5vh] h-[48px] rounded-[8px] bg-[black] text-white">Join the Waitlist</button>

        </div>

    </div>

    <div className="w-[87%] h-[0.5px] bg-black"></div>
    <div className="w-full flex justify-between px-[6vw] mt-[3vh] items-center"  >
        <p className="text-[10px] leading-[21px] font-[400]
        md:text-[14px]
        ">© 2024 Metapher.io  | All rights reserved.</p>
        <div className="flex ">
            <img src="/secondpage/footer/newone/Instagram.svg"
            
            onClick={()=>{
                window.open(' https://www.instagram.com/metapher.io/', '_blank', 'noopener,noreferrer');
               }}
            className="ml-[10px] hover:cursor-pointer" alt="" />

            <img src="/secondpage/footer/newone/linkedin.svg"
              onClick={()=>{
                window.open('https://www.linkedin.com/company/metapher', '_blank', 'noopener,noreferrer');
               }}
            className="ml-[10px] hover:cursor-pointer" alt="" />
            <img src="/secondpage/footer/newone/Twitter.svg"
              onClick={()=>{
                window.open('https://x.com/metapher_io', '_blank', 'noopener,noreferrer');
               }}
            className="ml-[10px]  hover:cursor-pointer" alt="" />
            <img src="/secondpage/footer/newone/youtube.svg"
              onClick={()=>{
                window.open(' https://www.youtube.com/@inmetapher', '_blank', 'noopener,noreferrer');
               }}
            className="ml-[10px]  hover:cursor-pointer" alt="" />
           
           
            
        </div>
    </div>

   </div>
   </>
  )
}

export default Footer