
import { Helmet } from "react-helmet"
import ForProductivitySection from "../AdditionalComponents/ForProductivitySection"
import "../assets/cssforProductivityPage.css"
const ThirdPageProductivity = () => {
    return (
        <>
          <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
            <div id="Features" className="
            
            min-h-[100vh] w-full bgSecondPage flex  flex-col justify-center items-center pb-[70px] ">
                <h1 className="font-[700]  text-center customcssfortextthirdpage
                mt-[10vh]
                md:mt-[15vh]
                lg:mt-[15vh]
                xl:mt-[15vh]
                2xl:mt-[15vh]
                text-[25px]
              
                md:text-[80px] md:leading-[96px]
                
                ">

                    Features That Transform
                    <br />
                    Your Content Creation Process
                </h1>


                <div className=" flex  relative  min-h-[979px] 
                flex-col
                md:flex-row md:mt-[15vh]
                mt-[5vh] 

                ">

                    <div className="flex-1 flex flex-col   mr-[20px]  justify-between
                     
                    
                    md:min-w-[45vw] md:mr-[20px] md:items-end
                    min-w-[100vw] items-end
                    ">
                        <ForProductivitySection
                            src="/ChangedProductivity/i-1.svg"
                            heading={"Task Management"}
                            comment={"Efficiently manage and assign tasks within your team"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-2.svg"
                            heading={"Task Automation"}
                            comment={"Automate repetitive tasks to save time and reduce errors"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-3.svg"
                            heading={"Collaboration Tools"}
                            comment={"Enhance teamwork with integrated collaboration features"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-4.svg"
                            heading={"Advance YouTube Analytics"}
                            comment={"Gain insights for content performance"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-5.svg"
                            heading={"Drafts & Revisions"}
                            comment={"Easily create and manage mulitple versions of content"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-.svg"
                            heading={"Integrated Guidelines"}
                            comment={"Maintain consistency with built-in guidelines"}
                        />




                    </div>
                    <div className="flex-1  flex flex-col  ml-[20px] justify-between
                    
                      md:min-w-[45vw] md:mr-[20px] md:items-start
                    min-w-[100vw] items-center
                    ">

                        <ForProductivitySection
                            src="/ChangedProductivity/i-7.svg"
                            heading={"Creative Tools"}
                            comment={"Access tools to boost creativity and content quality"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-8.svg"
                            heading={"AI-Assistance"}
                            comment={"AI for content ideation and productivity enhancements"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-9.svg"
                            heading={"Mood boards"}
                            comment={"organize and visualize creative ideas"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-10.svg"
                            heading={"Scripting Assistance"}
                            comment={"Assist with scripting to streamline content creation"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-11.svg"
                            heading={"Performance Tracking"}
                            comment={"Monitor team performance with detailed analytics"}
                        />
                        <ForProductivitySection
                            src="/ChangedProductivity/i-12.svg"
                            heading={"In-built strage"}
                            comment={"Store all your content and assets securely in one place"}
                        />

                    </div>

                    {/* <div className="w-[383px] h-[269px] rounded-[8px] bg-[rgba(127,106,160,0.24)] flex justify-center items-center ">
                                                                                                                                                                                                <img src="/DashboardImg.jpg" className="w-[196.6px] h-[159.06px]" alt="" />
                                                                                                                                                                                            </div>
                                                                                                                                                                                            <div className="text-white  flex flex-col  justify-start items-start">
                                                                                                                                                                                                <h1 className="ml-[49px] mt-[47px] mb-[18px]">Link YouTube Channels and Get Started</h1>
                                                                                                                                                                                                <p className="ml-[49px] w-[25vw] mb-[24px] font-[400] text-[rgba(146,135,164,1)]">Begin by creating your account. Download our app from
                                                                                                                                                                                                    the App Store or Google Play, or use our web version.</p>
                                                                                                                                                                                                <button className="bg-[rgba(91,89,246,1)] flex justify-center items-center h-[5vh] w-[5vw] rounded-[23px] ml-[49px] ">0.85x</button>

                                                                                                                                                                                            </div>
                                                                                                                                                                                            <h1 className="absolute bottom-[6px] right-[56px] customclassForAbsolute text-[48px] leading-[95px] font-[700]"> 01</h1>


                                                                                                                                                                                        </div>
                                                                                                                                                                                        <div className="customclassforcardThirdPage flex  relative w-[60vw]">

                                                                                                                                                                                            <img src="/secondpage/ThirdPageDiv/Background+Border2.svg" alt="" />


                                                                                                                                                                                        </div>
                                                                                                                                                                                        <div className="customclassforcardThirdPage flex  relative w-[50vw]">

                                                                                                                                                                                            <img src="/secondpage/ThirdPageDiv/Background+Border.svg" alt="" />
                                                                                                                                                                        */}

                </div>







            </div>
        </>
    )
}

export default ThirdPageProductivity