import { Helmet } from "react-helmet"
import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';


const FifthPageFeatures = () => {
      // Trigger the animation once when the element comes into view


const images = [

    '/secondpage/FifthPage/Frame 612.svg',
    '/secondpage/FifthPage/Frame 607.svg',
    '/secondpage/FifthPage/Frame 606.svg',
    '/secondpage/FifthPage/Frame 608.svg',
    '/secondpage/FifthPage/Frame 605.svg',
    
  ];



  const refs = images.map(() => useRef(null));  // Create refs for all images
  const inViewStates = refs.map((ref) => useInView(ref, { once: false }));  // Track visibility for each image
    return (
        <>  <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
            <div className="min-h-[100vh] flex flex-col items-center justify-start bg-[rgba(17,10,28,1)]">
                <h1 className="font-[700] text-[40px] text-center customcssfortextthirdpage mt-[10vh]
                
                md:text-[80px] md:leading-[96px] 
                ">
                    Discover What
                    <br />Our Platform Can Do
                </h1>

                <div 
                className="
                md:px-[0px]
                px-[2vw]
                "
                >
                    <img src="/secondpage/FifthPage/Container1.svg" className="block md:hidden" alt="" draggable="false" />

                    <div className="h-[452px] w-[82vw]   mt-[17vh] mb-[10vh]
                    hidden
                    md:flex
                    ">
                         <div className="  w-[655px] h-[405px] bg-[#80CF64] rounded-[24px]  flex align-center justify-start relative">
                           
                         {images.map((src, index) => {
        let animateProps = {};

        // Define how far each card should move based on its index
        if (index === 0) {
          // First card moves far left
          animateProps = { left: '0px', opacity: 1 };
        } else if (index === 1) {
          // Second card moves slightly left
          animateProps = { left: '100px', opacity: 1 };
        } else if (index === 2) {
          // Middle card stays in the center
          animateProps = { left: '200px', opacity: 1 };
        } else if (index === 3) {
          // Fourth card moves slightly right
          animateProps = { left: '300px', opacity: 1,zIndex:2 };
        } else if (index === 4) {
          // Fifth card moves far right
          animateProps = { left: '400px', opacity: 1,zIndex:3 };
        }

        return (
          <motion.img
            key={index}
            ref={refs[index]}  // Reference each image for visibility tracking
            src={src}
            alt={`Image ${index + 1}`}
            initial={{ left: '200px', opacity: 1 }}  // Start centered and hidden
            animate={inViewStates[index] ? animateProps : {}}  // Animate left or right
            transition={{ duration: 0.8, delay: index === 2 ? 0 : 0.3 }}  // Delay only for middle image
            style={{
              position: 'absolute',  // Images positioned absolutely within the relative div
              top: '50%',
              transform: 'translateY(-50%)',  // Vertically center the image
              width: '200px',
              height: '209px',
              objectFit: 'cover',
              borderRadius: '10px',
              margin: '10px',
            }}
          />
        );
      })}            

                         </div>
                         <img src="/secondpage/FifthPage/Container.svg"  className="h-[424px] w-[622px] " alt="" />
                    </div>
                    <img src="/secondpage/FifthPage/Container (2).svg" alt="" draggable="false" />
                    <img src="/secondpage/FifthPage/Container3.svg" alt="" draggable="false" />


                </div>

            </div>
        </>
    )
}

export default FifthPageFeatures