
import "./SignupPage.css"
// import "bootstrap/dist/css/bootstrap.min.css"
// import "./SignupPage.css"
import { useState } from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
// import { Alert } from "bootstrap";

import { FadeLoader } from "react-spinners"


const NewSignUpPage = () => {

    let [color] = useState("white")
    const emailRegex = /\S+@\S+\.\S+/
    const youtubeRegex =/^https:\/\/www\.you.*/;
      
  
    const override = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
      position: "relative"
    }
  
    const dataInitial = {
      name: "",
      email: "",
      youtubeLink: "",
      channel_name:""
    }
    const [nameerr, setNameerr] = useState(false)
    const [emailerr, setEmailerr] = useState(false)
    const [linkerr, setLinkerr] = useState(false)
    const [EmailFormatErrorState, setEmailFormatErrorState] = useState(false)
    // const [YoutubeLinkErrorState, setYoutubeLinkErrorState] = useState(false)
    const [channelNameErrorState, setChannelNameErrorState] = useState(false)
  
    const navigate = useNavigate()
    const [stateforLoading, setStateforLoading] = useState(false)
  
    const [dataInput, setDataInput] = useState(dataInitial)
    const onInputChange = (e) => {
      setDataInput({ ...dataInput, [e.target.name]: e.target.value });
      setNameerr(false);
      setLinkerr(false);
      setEmailerr(false);
      setEmailFormatErrorState(false);
      // setYoutubeLinkErrorState(false);
      setChannelNameErrorState(false);
    }
  
    const [isChecked, setIsChecked] = useState(false);
  
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
  
    const formSubmit = (e) => {
      e.preventDefault()
      if (dataInput.name.length <= 0) {
        setNameerr(true)
      }
      if (dataInput.email.length <= 0) {
        setEmailerr(true)
      }
      if (dataInput.youtubeLink.length <= 0) {
        setLinkerr(true)
      }
      if(dataInput.channel_name.length <=0){
        setChannelNameErrorState(true);
      }
  
      const EmailFormatValid = emailRegex.test(dataInput.email)
      const YoutubeLinkValid = youtubeRegex.test(dataInput.youtubeLink)
      if (!EmailFormatValid) {
        setEmailFormatErrorState(true)
      }
      // if (!YoutubeLinkValid) {
      //   setYoutubeLinkErrorState(true)
      //   return
      // }
  
      if (
        dataInput.name &&
        dataInput.email &&
        dataInput.youtubeLink &&
        EmailFormatValid &&
        // YoutubeLinkValid &&
        dataInput.channel_name
      ) {
        setStateforLoading(true)
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/metapher/userWaitlist`, dataInput)
          .then((err, res) => {
            if (err) {
              console.log(err)
            }
            console.log(res)
            setTimeout(() => {
              navigate("/e/SignUpDone")
            }, 2000)
          })
          .catch((err) => {
            setStateforLoading(false)
            toast.error("Something went wrong. Try Again", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            })
            console.log(err)
           
          })
      }
    }
 

  return (
    <>
     <div className="flex">
        <div className="
        h-[100vh]
        
        md:w-[calc(100vw-911px)] 
        w-full
        ">
        <div className="container">
        <div className="custom-section mx-auto">
          <img src="/magpiie logo.svg" className="img-set h-[36px] "  alt="not found"/>
          <h3 className="signup-description

          md:w-[100vw] md:ml-[23px]
          w-[100vw] 
          
          
          ">Be Among The First To Try Out Magpiie</h3>

          <hr />

          <form className="signup-form signup-basic-form">
            <div className="custom-form-item">
              <label htmlFor="name">Full Name</label>
              <input
                id="name"
                type="text"
                placeholder="Enter Full Name"
                name="name"
                value={dataInput.name}
                onChange={onInputChange}
                required
              />
              {nameerr && (
                <p style={{ fontSize: "10px", color: "red", marginLeft: "5px" }}>Name Required</p>
              )}
            </div>

            <div className="custom-form-item">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="EMAIL"
                name="email"
                value={dataInput.email}
                onChange={onInputChange}
                required
              />
              {emailerr && (
                <p style={{ fontSize: "10px", color: "red", marginLeft: "5px" }}>Email Required</p>
              )}
              {!emailerr && EmailFormatErrorState && (
                <p style={{ fontSize: "10px", color: "red", marginLeft: "5px" }}>
                  Invalid Format should include @xyz.com
                </p>
              )}
            </div>

            <div className="custom-form-item">
              <label htmlFor="channel_name">Youtube Channel Name</label>
              <input
                id="channel_name"
                placeholder="Youtube Channel Name"
                name="channel_name"
                value={dataInput.channel_name}
                onChange={onInputChange}
                required
              />
              {channelNameErrorState && (
                <p style={{ fontSize: "10px", color: "red", marginLeft: "5px" }}>
                  channel Name Required
                </p>
              )}
             
            </div>
            <div className="custom-form-item">
              <label htmlFor="youtubeLink">Youtube Channel Link</label>
              <input
                id="youtubeLink"
                placeholder="Youtube Channel link"
                name="youtubeLink"
                value={dataInput.youtubeLink}
                onChange={onInputChange}
                required
              />
              {linkerr && (
                <p style={{ fontSize: "10px", color: "red", marginLeft: "5px" }}>
                  youtube link Required
                </p>
              )}
              {/* {!linkerr && YoutubeLinkErrorState && (
                <p style={{ fontSize: "10px", color: "red", marginLeft: "5px" }}>Invalid Link</p>
              )} */}
            </div>
            <div className="my-[31px]">
            <label className="">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
       &nbsp; I agree to the <p className="inline hover:cursor-pointer text-blue-500 underline"  onClick={()=>{
        navigate("/Terms-And-Conditions");
       }} >terms and conditions</p>
      </label>
            </div>
            <button className={`btn-submit  ${isChecked?" !bg-[#625072] hover:cursor-pointer":"!bg-[#e0caf4] "}`} onClick={formSubmit}  disabled={!isChecked}>
              Join Now
            </button>
          </form>
        </div>
      </div>
      {stateforLoading && (
        <div className="backgroundcolorofalert">
          <FadeLoader
            color={color}
            loading={true}
            cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        </div>
        <div className="w-[911px] h-[100vh] bgforsignuppage 
        hidden
        md:block
        ">


        </div>


    </div>
    </>
  )
}

export default NewSignUpPage
