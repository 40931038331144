import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
// import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Footer from '../Footer';
import "./conditioncss.css"
import NavbarCondition from './NavbarCondition';
import NavbarforCondition from './NavbarforCondition';
import { Helmet } from 'react-helmet';

const TermsOfService = () => {

    const nav = useNavigate();
    return (
        <>
          <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
           {/* <NavbarCondition/> */}

<NavbarforCondition/>
            <div className='min-h-[calc(100vh - 66px)] w-full flex  mt-[16vh]  pb-[10vh] shadow-md'>
                <div className='flex-[0.25]  flex-col items-center  border-r  
                hidden
                md:flex
                '>
                    <div className='flex flex-col mt-[0vh] items-start h-[20vh] justify-around w-[11vw]' >
                        <h1 className='font-Inter font-[600] text-[14px] leading-[16px] text-[rgba(68,68,68,1)]'>TERMS AND AGREEMENTS</h1>
                        <div className='flex flex-col h-[12vh] justify-around'>
                            <p className='font-Inter font-[500] text-[14px] leading-[16px]  text-[rgba(20,110,245,1)]'>Terms of Service</p>
                            {/* <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)]'>Product Terms</p> */}
                            {/* <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)]'>Creator Aggrement</p> */}
                        </div>

                    </div>

                    <div className='flex flex-col mt-[1vh] items-start h-[20vh] justify-around w-[11vw]' >
                        <h1 className='font-Inter font-[600] text-[14px] leading-[16px] text-[rgba(68,68,68,1)]'>PRIVACY RESOURCES</h1>
                        <div className='flex flex-col h-[12vh] justify-around'>
                            <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)] '
                            onClick={()=>{
                                nav("/Terms-And-Conditions")
                            }}
                            >Privacy Policy</p>
                            <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)]'
                             onClick={()=>{
                                nav("/CookiesPolicy")
                            }}
                            >Cookie Policy</p>
                            {/* <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)]'>Data Processing</p> */}
                        </div>

                    </div>
                    <div>

                    </div>

                </div>

                <div className='flex-1 flex flex-col pl-[4vw]'>
                    <div className='w-[958px]'>
                        <h1 className='font-Inter font-[600] leading-[48.41px] text-[rgba(89,75,113,1)]
                        md:text-[40px] 
                        text-[25px]
                        '>END USER LICENSE AGREEMENT</h1>
                        <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)] mt-[5vh] '>Effective date:&nbsp; August 17, 2024</p>
                        <p className='font-Inter font-[500] text-[14px] leading-[16px] text-[rgba(102,102,102,1)] mt-[1vh] mb-[8vh]'>Effective date:&nbsp; August 17, 2024</p>


                    </div>
                    <div className='w-[958px] bg-black h-[0.1px]'></div>

                    <div className='flex w-[958px] mt-[6vh] relative'>
                        <div className='w-[631px] flex flex-col text-justify'>
                            <div
                                id='InterpretationAndDefinitions'
                                className='w-[631px]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>1. DEFINITIONS AND INTERPRETATIONS

                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>

                                    <br />
                                    &nbsp;&bull;&nbsp;"Agreement" or "License" or "EULA" shall mean this End User License Agreement.
                                    <br /><br />

                                    &nbsp;&bull;&nbsp;"Licensee" or "You" or "Your" shall mean You, the individual or business entity licensing the Product under the terms of this Agreement.
                                    <br /><br />

                                    &nbsp;&bull;&nbsp;"Intellectual Property" means current and future worldwide rights under patent law, copyright law, trade secret law, trademark law, moral rights law, and other similar rights.
                                    <br /><br />
                                    &nbsp;&bull;&nbsp;"Update" means maintenance of, or a fix to, a version of the Product, including, but not limited to, a hotfix, patch, or enhancement, none of which function as a standalone service or other software package and which do not have an additional cost for any existing Licensee.
                                    <br /><br />
                                    &nbsp;&bull;&nbsp;"Upgrade" means a major, standalone version of the Product, which may include additional applications, features, or functionality.
                                    <br /><br />
                                    &nbsp;&bull;&nbsp;A person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality) and that person's legal and personal representatives, successors, and permitted assigns.
                                    <br /><br />
                                    &nbsp;&bull;&nbsp;Words in the singular shall include the plural and vice versa.
                                    <br />
                                    <br />

                                    &nbsp;&bull;&nbsp;A reference to one gender shall include a reference to the other genders.
                                    <br />
                                    <br />
                                    &nbsp;&bull;&nbsp;A reference to a statute, statutory provision, or subordinate legislation is a reference to it as it is in force from time to time, taking account of any amendment or reenactment, and includes any statute, statutory provision, or subordinate legislation which it amends or reenacts; provided that, as between the Parties, no such amendment or reenactment shall apply for the purposes of this Agreement to the extent that it would impose any new or extended obligation, liability, or restriction on, or otherwise adversely affect the rights of, any Party.
                                    <br />
                                    <br />
                                    &nbsp;&bull;&nbsp;A reference to writing or written includes e-mail.
                                    <br />
                                    <br />
                                    &nbsp;&bull;&nbsp;Any obligation in this Agreement on a person not to do something includes an obligation not to agree or allow that thing to be done.
                                    <br />
                                    <br />
                                    &nbsp;&bull;&nbsp;Any phrase introduced by the terms "including", "include", "in particular", or any similar expression shall be construed as illustrative and shall not limit the sense of the words preceding those terms.
                                    <br />
                                    <br />
                                    &nbsp;&bull;&nbsp;References to articles, sections, or clauses are to the articles, sections, and clauses of this Agreement.
                                    <br />
                                    <br />
                                    &nbsp;&bull;&nbsp;"We", "us", and "our" means Metapher Media Labs.
                                    <br />
                                    <br />






                                </p>

                            </div>
                            <div
                                id='CollectionAndUsingYourPersonalData'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>2. LICENSE GRANT
                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>
                                    <br />

                                    Metapher Media Labs grants You a non-exclusive license to use and install the Product subject to all the terms and conditions set forth herein. This EULA shall also govern any and all software Updates and Upgrades provided by Metapher Media Labs that replace, overwrite, and/or supplement the original installed version of the Product unless those other Updates and Upgrades are covered under a separate license, in which case the terms of that license will govern.

                                </p>

                            </div>
                            <div
                                id='UseofPersonalData'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>3.TERMINATION

                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>
                                    <br />
                                    <br />
                                    Should You breach this EULA, Your right to use the Product will immediately terminate without notice. However, all provisions of this EULA, except for the License grant, shall survive termination and remain in effect. Upon termination of the License grant, You MUST destroy any and all copies of the Product.


                                </p>

                            </div>

                            <div
                                id='ChildrenPolicy'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>4. INTELLECTUAL PROPERTY PROTECTED PRODUCT
                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>

                                    The Product is protected by copyright and other Intellectual Property laws and treaties. All rights, title, and interest in and to the content offered, including but not limited to any photographs, images, video animation, text, and music incorporated as part of the offered content, belong to Metapher Media Labs. Offered content must be treated as copyrighted material, except that You may make copies as provided by the License. However, printed material accompanying any offered content may not be copied.
                                </p>

                            </div>

                            <div
                                id='DeleteYourPersonalData12'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>5. NO GRANT OF RIGHTS
                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>
                                    {/* <br /> */}
                                    {/* <br /> */}
                                    Except as expressly stated herein, this Agreement does not grant either Party any rights to the other's content or any of the other's Intellectual Property. Specifically, Metapher Media Labs owns all Intellectual Property rights in the Product.

                                </p>

                            </div>
                            <div
                                id='Disclosureofyourpersonaldata'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>6. RIGHT OF PUBLICITY
                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>You agree that: (a) Metapher Media Labs may include Licensee or its brand in a list of Licensees, online, or in promotional materials; and (b) Metapher Media Labs may verbally reference Licensee as a licensee of the Product.

                                </p>

                            </div>


                            <div
                                id='ChildrenPolicy2'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>7. RESTRICTIONS ON USE

                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>As a Licensee, You may not: (a) Use the offered content on more than one computer at a time, without prior purchase of additional licenses; (b) Share, distribute, lend, lease, sublicense, or otherwise make available, in any manner whatsoever, to any third party the offered content; (c) Modify, adapt, create derivative works from, or translate any part of the offered content, except within Your work in accordance with this License; (d) Reverse engineer, decompile, or disassemble the offered content, nor attempt to locate or obtain its source code; (e) Alter or remove any trademark, copyright, or other proprietary notice contained within the offered content; or (f) Use any offered content in any manner not stipulated within this EULA or the documentation accompanying the offered content.
                                </p>

                            </div>
                            <div
                                id='LinkstootherWebsites'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>8. UPDATES/UPGRADES                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>Metapher Media Labs may make Updates or Upgrades available for the Product, in accordance with the terms and conditions of this EULA. Metapher Media Labs may conditionally release Updates or Upgrades to You upon Your acceptance of another EULA or execution of another separate agreement. Should You elect to install and use these updates, You agree to be subject to all applicable license terms and conditions of this EULA and/or any other agreement. </p>

                            </div>
                            <div
                                id='warranty'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>9. DISCLAIMER OF WARRANTY
                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>Metapher Media Labs shall use reasonable efforts consistent with prevailing industry standards to maintain the Product in a manner that minimizes errors and interruptions. HOWEVER, METAPHOR MEDIA LABS DOES NOT WARRANT THAT THE PRODUCT WILL BE UNINTERRUPTED OR ERROR-FREE, NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USING THE PRODUCT. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER PARTY OR ANY OFFICER, DIRECTOR, SUBSIDIARY, AFFILIATE, OR EMPLOYEE OF EITHER PARTY, MAKES ANY OTHER WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, AND NONINFRINGEMENT. NEITHER METAPHOR MEDIA LABS NOR ANY OFFICER, DIRECTOR, SUBSIDIARY, AFFILIATE, OR EMPLOYEE OF METAPHOR MEDIA LABS MAKES ANY REPRESENTATION OR WARRANTY ABOUT ANY CONTENT OR INFORMATION MADE ACCESSIBLE BY OR THROUGH THE PRODUCT.  </p>

                            </div>
                            <div
                                id='warranty10'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>10. LIMITATION OF LIABILITY AND REMEDIES

                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>In spite of any damages You may incur for any reason, which may include, but are not limited to, any and all direct or general damages, the entire liability of Metapher Media Labs and/or any of the aforementioned suppliers covered under the provisions of this EULA, along with Your exclusive remedy, shall be limited to the amount actually paid by You for this Product. The aforementioned limitations, exclusions, and disclaimers shall apply to the maximum extent allowable by law, even should any proposed remedy fail its essential purpose.
                                </p>

                            </div>
                            <div
                                id='warranty11'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>11. EXPORT CONTROLS

                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>By installing the Product, You agree to comply with any and all applicable export laws, restrictions, and regulations of any relevant authority, including those of the United States, India, or any other applicable jurisdiction. You expressly agree not to export or re-export, nor allow the export or re-export of the offered content in violation of any such law, restriction, or regulation.
                                </p>

                            </div>
                            <div
                                id='warranty12'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>12. GOVERNMENT END USERS
                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>The offered content is licensed by the government with RESTRICTED RIGHTS. The use, duplication of, or disclosure by the government shall be subject to restrictions in accordance with relevant clauses as applicable.
                                </p>

                            </div>
                            <div
                                id='warranty13'
                                className='w-[631px] mt-[6vh]'>
                                <h1 className='font-Inter font-[600] text-[24px] leading-[32px] text-[rgba(89,75,113,1)]'>13.MISCELLANEOUS

                                </h1>
                                <p className='font-Inter font-[500] text-[14px] leading-[24px] text-[rgba(68,68,68,1)] w-[90vw] md:w-[631px] tracking-[0.016rem]
                                        mt-[2vh]'>


                                    <h3 className='mb-[2vh] text-[15px] text-black font-[600] inline'>
                                        Successors and Assigns:&nbsp;
                                    </h3>
                                    &nbsp;
                                    EULA shall be legally binding upon and inure to the benefit of Metapher Media Labs and You, our respective successors, and permitted assigns.
                                    <br />
                                    <br />
                                    <h3 className='mb-[2vh] text-[15px] text-black font-[600] inline'>
                                        Severability:&nbsp;
                                    </h3>
                                    &nbsp;
                                    If any provision of this Agreement is held to be illegal, invalid, or unenforceable by a court of competent jurisdiction, the remaining provisions shall not be affected.

                                    <br />
                                    <br />
                                    <h3 className='mb-[2vh] text-[15px] text-black font-[600] inline'>
                                        Waiver:&nbsp;
                                    </h3>

                                    Any waiver of any breach or failure to enforce any of the provisions contained herein shall not be deemed a future waiver of said terms or a waiver of any other provision of this EULA.
                                    <br />
                                    <br />
                                    <h3 className='mb-[2vh] text-[15px] text-black font-[600] inline'>
                                        Amendments:&nbsp;
                                    </h3>

                                    Any waiver, supplementation, modification, or amendment to any provision of this EULA shall only be effective when done so in writing and signed by Metapher Media Labs and You.

                                    <br />
                                    <br />
                                    <h3 className='mb-[2vh] text-[15px] text-black font-[600] inline'>
                                        Governing Law:&nbsp;
                                    </h3>
                                    This EULA shall be governed by and construed in accordance with the laws of India.

                                    <br />
                                    <br />
                                    <h3 className='mb-[2vh] text-[15px] text-black font-[600] inline'>
                                        Dispute Resolution:&nbsp;
                                    </h3>
                                    All disputes arising under this Agreement shall be subject to mediation under the Indian Arbitration and Conciliation Act, 1996, and courts in India shall have exclusive jurisdiction.
                                    ACKNOWLEDGEMENT
                                    BY INSTALLING OR USING THE PRODUCT, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS EULA, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS.

                                    <br />
                                    <br />
                                    <h3 className='mb-[2vh] text-[15px] text-black font-[600] inline'>
                                        Contact Information:&nbsp;
                                    </h3>
                                      If you have any questions about this Agreement, please contact:
                                    Metapher Media Labs
                                    Email: contact@metapher.io
                                    Address: 18, Mangal Park, three Hanuman Road, Deesa, Gujarat, India





                                </p>

                            </div>






                        </div>

                        <div className='w-[258px] ml-[4vw]   relative h-full'>
                            <div className='sticky top-[53px]'>
                                <h1 className='font-[600] font-Inter text-[14px] leading-[16px] mb-[4vh] tracking-[0.016rem]'>TABLE OF CONTENTS</h1>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>   <Link
                                    activeClass="active"
                                    to="InterpretationAndDefinitions"
                                    spy={true}
                                    smooth={true}
                                    duration={2000}
                                    className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                >
                                    1. DEFINITIONS AND INTERPRETATIONS
                                </Link></p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="CollectionAndUsingYourPersonalData"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >

                                        2. LICENSE GRANT
                                    </Link>
                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="UseofPersonalData"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >

                                        3. TERMINATION
                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="ChildrenPolicy"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >


                                        4. INTELLECTUAL PROPERTY PROTECTED PRODUCT
                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="DeleteYourPersonalData12"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >



                                        5. NO GRANT OF RIGHTS
                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>

                                    <Link
                                        activeClass="active"
                                        to="Disclosureofyourpersonaldata"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >



                                        6. RIGHT OF PUBLICITY

                                    </Link>


                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="ChildrenPolicy2"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >




                                        7. RESTRICTIONS ON USE

                                    </Link>


                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>
                                    <Link
                                        activeClass="active"
                                        to="LinkstootherWebsites"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >




                                        8.  UPDATES/UPGRADES    


                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>

                                    <Link
                                        activeClass="active"
                                        to="warranty"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >

                                        9. DISCLAIMER OF WARRANTY


                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>

                                    <Link
                                        activeClass="active"
                                        to="warranty10"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >

10. LIMITATION OF LIABILITY AND REMEDIES


                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>

                                    <Link
                                        activeClass="active"
                                        to="warranty11"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >

                11. EXPORT CONTROLS


                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>

                                    <Link
                                        activeClass="active"
                                        to="warranty12"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >

12. GOVERNMENT END USERS


                                    </Link>

                                </p>
                                <p className='mt-[3vh] font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)]'>

                                    <Link
                                        activeClass="active"
                                        to="warranty13"
                                        spy={true}
                                        smooth={true}
                                        duration={2000}
                                        className="font-Inter font-[500] text-[14px] leading-[16px] tracking-[0.016rem] text-[rgba(102,102,102,1)] "
                                    >

13.MISCELLANEOUS


                                    </Link>

                                </p>
                            </div>



                        </div>



                    </div>



                </div>



            </div>

            <Footer />


        </>
    )
}

export default TermsOfService