import react from "react"
import { useNavigate } from "react-router-dom";
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import NavbarResponsiveComponentSM from "../../responsiveComponents/NavbarResponsive/NavbarResponsiveComponentSM";
const NavbarforCondition=()=>{




    const nav = useNavigate();
    const navigate = useNavigate();
    return(<>
    
    <nav className="w-[95vw] h-[66px]   mx-[auto] sticky-navbar   left-[2%]
             md:block
             hidden
            ">
              <div className="w-full h-full my-0 mx-[auto] rounded-custom32px   py-0 px-[30px]  flex justify-between items-center
  bg_customcss_nav
  ">

                <div className="h-full flex items-center">
                 <img src="/magpiie logo.svg" className="w-[149px] h-[37px] cursor-pointer " alt="not available" onClick={() => {
                    nav("/");
                    scrollToTop();

                  }} />
                  {/* <p>MAGPIIE</p> */}
                </div>
                <div className="w-[45%]">
                  <ul className="flex w-[94%] justify-around">

                    <li className="cursor-pointer ">
                    <Link
                            activeClass="aactive"
                            to="Features"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                            onClick={()=>{
                              navigate('/', { state: { section: 'Features' } });
                          }}
                        >
                          Features
                        </Link>
                  
                    </li>
                    <li className="cursor-pointer ">
                    <Link
                            activeClass="aactive"
                            to="solutions"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                            onClick={()=>{
                              navigate('/', { state: { section: 'solutions' } });
                          }}
                        >Solutions</Link>
                    </li>
                    <li className="cursor-pointer "
                    // onClick={() => {
                    //   nav("/AboutUs");

                    // }}
                    >  <Link
                    activeClass="aactive"
                    to="AboutUs"
                    spy={true}
                    smooth={true}
                    duration={2000}
                    className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                    onClick={()=>{
                      navigate('/', { state: { section: 'AboutUs' } });
                  }}
                >About Us</Link></li>
                    <li className="cursor-pointer ">
                    <Link
                            activeClass="aactive"
                            to="ourteam"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                            onClick={()=>{
                              navigate('/', { state: { section: 'ourteam' } });
                          }}
                        >Our Team</Link>
                    </li>
                    <li className="cursor-pointer ">
                    <Link
                            activeClass="aactive"
                            to="contactUs"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            className="text-black font-Inter font-[400] text-[16px] leading-[24px] "
                            onClick={()=>{
                              navigate('/', { state: { section: 'contactUs' } });
                          }}
                        >Contact</Link>
                    </li>
                  </ul>

                </div>
                <div>
                  <button
                    onClick={() => {
                      nav("/e/Register");
                    }}
                    className="w-[180px] font-Inter font-[400] text-[16px] leading-[24px] tracking-[2px]   h-[52px] rounded-[8px] bg-[rgba(17,10,28,1)] text-white ">
                    JOIN WAITLIST
                  </button>
                </div>


              </div>


            </nav>

            <NavbarResponsiveComponentSM/>
    </>)



}


export default NavbarforCondition

