import React from 'react'
import "./prodcss.css"
const ForProductivitySection = ({src,heading,comment}) => {
  return (
   <>
    <div className=" flex justify-start items-center rounded-[24px] imgforproduct
     mt-[1vh] mb-[0px]
    
    md:w-[564px] md:h-[108px] md:my-[0px]
    h-[13vh] w-[92vw] 
    
    ">
                            <img  className="w-[56px] 
                            md:h-[56px]
                            h-[38px]
                            ml-[2vw]" src={src} alt="" />
                            <div className="flex flex-col ml-[1vw]  h-[60px] justify-around  ">
                                <h1 className="font-Inter font-[500]  leading-[24px] text-[white]
                                md:text-[20px] 
                                text-[13px]
                                ">{heading}</h1>
                                <p className="font-Inter font-[400]  leading-[20px] text-[rgba(149,139,167,1)]
                                md:text-[16px]
                                text-[12px]
                                ">{comment}</p>

                            </div>

                        </div>
   </>
  )
}

export default ForProductivitySection