import { Helmet } from "react-helmet"


const FourthPageLookingFor = () => {
  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
      <div id="solutions" className="min-h-[100vh] w-full flex flex-col items-center">

        <div className="flex flex-col justify-center items-center 
        mt-[5vh]
        md:mt-[15vh]
        ">
          <h1 className="font-[600] leading-[60px] text-center text-[rgba(89,75,113,1)]
            
            md:text-[50px] 
             text-[25px]
            ">
            A Closer Look at What We Offer
          </h1>
          <p className="font-[400]  font-Inter leading-[24px] text-center text-[rgba(89,75,113,1)] mt-[25px]
            
            md:text-[20px] md:w-[28vw]
            text-[10px] w-[100vw]

            ">Discover How Our Features Empower Your  <br />
            Content Creation Process</p>

        </div>

        <div className="w-full h-full flex justify-center px-[5vw] md:px-[0vw]">

          <img src="/secondpage/fourthpage/Container (2).svg" className="mt-[3vh]
mx-[2vw] 
md:mt-[15vh]
pb-[15vh]" alt="" />
        </div>

      </div>
    </>
  )
}

export default FourthPageLookingFor