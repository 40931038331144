import React from "react";
import "./AfterSignup.css";
import { Helmet } from "react-helmet";

const AfterSignup = () => {
  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-8G99FQFDKS"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8G99FQFDKS');
          `}
        </script>
      </Helmet>
      <div className="firstDiv" style={{ marginTop: "50px" }}>
        <img src="/magpiie logo.svg" style={{ width: "300px" }} alt="" />
        <p className="congrats  
        
        ">Congratulations on Joining the Magpiie Community!</p>
        <h1 className="descriptionforMagpiie
         md:w-[57vw]
        w-[95vw]
        "> Welcome to a World of Inspiration and Creativity!</h1>
        <p className="p-under-the-description
        md:w-[43vw]
        w-[95vw]
        ">
          we'll keep you informed every step of the way. When our application is set to launch, you can expect to receive emails or notifications that will guide you into this exciting new phase.
        </p>
      </div>
    </>
  );
};

export default AfterSignup;
