import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Hamberger from './Hamberger';
import Practise from "../../practise"

const NavbarResponsiveComponentSM = () => {


    
  const nav = useNavigate();
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 2000,
      smooth: 'easeInOutQuart'
    });
  };

  const [HambergerMenu, setHambergerMenu] = useState(false)


  return (
  <>
  
  <nav className="w-[95vw] h-[66px]   mx-[auto] sticky-navbar  left-[1%]
                        md:hidden
         
            ">
              <div className="w-full h-full my-0 mx-[auto] rounded-custom32px   py-0 px-[30px]  flex justify-between items-center
  bg_customcss_nav
  ">

                <div className="h-full flex items-center">

                 <img src="/magpiie logo.svg" className="w-[25vw] cursor-pointer " alt="not available" onClick={() => {
                    nav("/");
                    scrollToTop();

                  }} />
                  {/* <p>MAGPIIE</p> */}
                </div>


{/*             here to place the new code for hamberger   
               */}


<Hamberger opened={HambergerMenu} setOpened={setHambergerMenu}     />





              </div>


            </nav>

         {/* <div className='mt-[30vh] ml-[30vw]'> 


         </div> */}
  
  </>
  )
}

export default NavbarResponsiveComponentSM